import { navigate } from "gatsby-link";
import React, { useEffect } from "react";

/**
 * This 404 page only appears when a user navigates to a localized
 * route that doesn't exist AND there is no localized 404 for that locale.
 */
const NotFoundPage: React.FC = () => {
  useEffect(() => {
    // Automatically redirect to default (en-us) 404 page.
    navigate("/en-us/404/");
  }, []);

  return <h1>404 Not Found (default)</h1>;
};

export default NotFoundPage;
